import { Component } from 'solid-js'
import DashboardCollaborators from '~/components/dashboard-collaborators/dashboard-collaborators'
import DashboardDiscord from '~/components/dashboard-discord/dashboard-discord'
import DashboardUser from '~/components/dashboard-user/dashboard-user'
import DashboardWorks from '~/components/dashboard-works/dashboard-works'
import styles from '~/components/dashboard/dashboard.module.scss'

const Dashboard: Component = () => {  
  return (
    <div class={styles.dashboard}>
      <aside class={styles.aside}>
        <DashboardUser />
        <DashboardCollaborators />
        <DashboardDiscord />
        {/* <DashboardFreePdf /> */}
      </aside>
      <DashboardWorks />
    </div>
  )
}

export default Dashboard
